import { Component, Input } from '@angular/core';
import { SafePipe } from '../../pipes/safe.pipe';
import { AccordionDirective } from '../../directives/accordion.directive';


@Component({
    selector: 'app-bottom-text',
    templateUrl: './bottom-text.component.html',
    styleUrls: ['./bottom-text.component.scss'],
    imports: [AccordionDirective, SafePipe]
})
export class BottomTextComponent {

  @Input('text') text: string;

  constructor() { }

}
